<template>
  <div class="exception-body error">
    <img src="assets/layout/images/logo-white.svg" alt="diamond-layout" class="logo" />

    <div class="exception-content">
      <div class="exception-title">OOPS !</div>
      <div class="exception-detail">Une erreur s'est produite. Veuillez réésayer.</div>
      <router-link to="/menu/payment">Retour</router-link>
    </div>
  </div>
</template>



<script>
export default {};
</script>